import React, {useEffect, useState} from "react";
import styles from './OrderSearchFilters.module.scss';
import searchIcon from "../../../../assets/icons/search.svg";
import {getAccessToken, getSearchResult} from "../../../../api/api";
import {useAppDispatch} from "../../../../hooks/redux";
import {setOrders} from "../../../../store/main-slice";

const OrderSearchFilters = ({setOrdersFilter, searchValue, setSearchValue, filterLabels, setFilterLabels}) => {
    const dispatch = useAppDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    let timeout;

    const sendSearch = (value = null) => {
        setIsDisabled(true);
        getAccessToken().then((token) => {
            getSearchResult(token, searchValue, value ? value.is_order : true, value ? value.is_fabric : true, value ? value.is_warehouse_item : true).then((resp) => {

                if (resp && resp.response && resp.response.orders && resp.response.orders !== "No orders found" && resp.response.orders.length > 0) {
                    setOrdersFilter('all');
                    dispatch(setOrders(resp.response.orders));
                    setIsDisabled(false);
                    if (resp && resp.filter && resp.filter) {
                        setFilterLabels(resp.filter)
                    }
                } else {
                    console.log(resp.response.orders);
                    setIsDisabled(false);
                }
            })
        })
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout);
            if (filterLabels !== null) {
                setFilterLabels(null);
            }
            timeout = setTimeout(() => {
                sendSearch();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [searchValue]);


    return (
        <>
            <div className={styles.search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                       onChange={(e) => setSearchValue(e.target.value)}/>
                <img src={searchIcon} alt=""/>
            </div>
            {filterLabels && (filterLabels.is_order || filterLabels.is_fabric || filterLabels.is_warehouse_item) && (
                <div className={styles.filterButtons}>
                    <button disabled={isDisabled} className={filterLabels.is_order ? styles.btnFilterActive : styles.btnFilter} onClick={() => {
                        sendSearch({is_fabric: filterLabels.is_fabric, is_order: !filterLabels.is_order, is_warehouse_item: filterLabels.is_warehouse_item})
                    }}>
                        <p>Замовлення</p>
                        <span/>
                    </button>
                    <button disabled={isDisabled} className={filterLabels.is_fabric ? styles.btnFilterActive : styles.btnFilter}onClick={() => {
                        sendSearch({is_fabric: !filterLabels.is_fabric, is_order: filterLabels.is_order, is_warehouse_item: filterLabels.is_warehouse_item})
                    }}>
                        <p>Тканини</p>
                        <span/>
                    </button>
                    <button disabled={isDisabled} className={filterLabels.is_warehouse_item ? styles.btnFilterActive : styles.btnFilter}onClick={() => {
                        sendSearch({is_fabric: filterLabels.is_fabric, is_order: filterLabels.is_order, is_warehouse_item: !filterLabels.is_warehouse_item})
                    }}>
                        <p>Склад</p>
                        <span/>
                    </button>
                </div>
            )}
        </>

    )
}

export default OrderSearchFilters;