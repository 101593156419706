import React, {useEffect, useState} from "react";
import styles from './Main.module.scss';
import UserHeader from "./UserHeader/UserHeader";
import MainTabs from "./MainTabs/MainTabs";
import searchIcon from '../../../assets/icons/search.svg';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getOrderList, getUser} from "../../../store/selectors";
import Order from "./Order/Order";
import {getAccessToken, getOrders, getOrdersRecentViews} from "../../../api/api";
import {setOrders} from "../../../store/main-slice";
import OrderSearchFilters from "./OrderSearchFilters/OrderSearchFilters";

let save = 0;

const Main = () => {
    const orderList = useAppSelector(getOrderList);
    const {id} = useAppSelector(getUser);
    const [ordersFilter, setOrdersFilter] = useState('my');
    const [searchValue, setSearchValue] = useState('');
    const [filterLabels, setFilterLabels] = useState(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (save === 0) {
            save = 1
            getAccessToken().then((token) => {
                getOrders(token, id).then(result => dispatch(setOrders(result)));
            })
        }

    }, [id])

    const onResetSearch = () => {
        setSearchValue('');
        setFilterLabels(null)
    }

    const onChangeList = (value) => {
        if (value) {
            getAccessToken().then((token) => {
                if (value === 'all') {
                    getOrders(token).then(result => dispatch(setOrders(result)));
                    onResetSearch()
                }
                if (value === 'my') {
                    getOrders(token, id).then(result => dispatch(setOrders(result)));
                    onResetSearch()
                }
                if (value === 'favorite') {
                    getOrders(token, id, true).then(result => dispatch(setOrders(result)));
                    onResetSearch()
                }
                if (value === 'history') {
                    getOrdersRecentViews(token, id).then(result => dispatch(setOrders(result)));
                    onResetSearch()
                }
            })
        }
    }

    return (
        <div className={styles.main}>
            <UserHeader/>
            <OrderSearchFilters setOrdersFilter={setOrdersFilter} searchValue={searchValue}
                                setSearchValue={setSearchValue} filterLabels={filterLabels}
                                setFilterLabels={setFilterLabels}/>
            <MainTabs orders={ordersFilter} setOrders={setOrdersFilter} onChangeList={onChangeList}/>
            {orderList && orderList.length > 0 && (
                <div className={styles.orderWrapper}>
                    {orderList.map((item, index) => (
                        <Order key={index} item={item}/>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Main;