import React, {useState} from "react";
import styles from './Calculator.module.scss';
import FabricTypeTabs from "./FabricTypeTabs/FabricTypeTabs";
import FabricSize from "./FabricSize/FabricSize";
import FabricOptions from "./FabricOptions/FabricOptions";
import FabricSet from "./FabricSet/FabricSet";
import CalcButton from "./CalcButton/CalcButton";
import FabricPrice from "./FabricPrice/FabricPrice";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import CommentPopup from "./Popups/CommentPopup/CommentPopup";
import OptionPopup from "./Popups/OptionPopup/OptionPopup";

const Calculator = () => {

    const fabricTypes = [
        {
            code: 'N015',
            fabrics: [
                {name: 'Котон', img: ''},
                {name: 'Сатин', img: ''},
                {name: 'Бавовна', img: ''},
                {name: 'Льон', img: ''}
            ]
        },
        {
            code: 'F3434',
            fabrics: [
                {name: 'Шовк', img: ''},
                {name: 'Атлас', img: ''},
                {name: 'Велюр', img: ''},
                {name: 'Батист', img: ''}
            ]
        }
    ];

    const [type, setType] = useState(fabricTypes[0].code);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [showOptionPopup, setShowOptionPopup] = useState(false);
    const dispatch = useAppDispatch();

    const calcSave = () => {
        console.log('calc save');
    }

    const openCommentPopup = () => {
        setShowCommentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const openOptionPopup = () => {
        setShowOptionPopup(true);
        dispatch(setIsActivePopup(true));
    }

    return (
        <div className={styles.calculator}>
            <h2>Тип тканини</h2>
            <FabricTypeTabs type={type} setType={setType} fabricTypes={fabricTypes}/>
            <h2>Розмір комплекту</h2>
            <FabricSize/>
            <h2 className={styles.setTitle}>Комплект</h2>
            <FabricSet openCommentPopup={openCommentPopup} openOptionPopup={openOptionPopup}/>
            <h2>Опції</h2>
            <FabricOptions/>
            <CalcButton action={calcSave}/>
            <FabricPrice/>
            {showCommentPopup && (
                <GlobalPopup topPopup title={'Коментар'} onClose={() => setShowCommentPopup(false)}>
                    <CommentPopup/>
                </GlobalPopup>
            )}
            {showOptionPopup && (
                <GlobalPopup topPopup title={'Опції наволочки:'} onClose={() => setShowOptionPopup(false)}>
                    <OptionPopup/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default Calculator;