import React, {useEffect, useState} from "react";
import styles from './DeliveryNP.module.scss';
import {useFormik} from "formik";
import './delivery.scss'
import NovaPoshtaCities from "./NovaPoshtaCities/NovaPoshtaCities";
import NovaPoshtaDepartments from "./NovaPoshtaDepartments/NovaPoshtaDepartments";
import NovaPoshtaWeight from "./NovaPoshtaWeight/NovaPoshtaWeight";
import DeliveryIPay from "./DeliveryIPay/DeliveryIPay";
import copyIcon from "../../../assets/icons/copy.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {getAccessToken, setDeliverNP} from "../../../api/api";
import Parser from "../../Common/Parser/Parser";

const DeliveryNP = ({deliveryOrder, lastOrderId}) => {

    const [city, setCity] = useState('');
    const [cityValue, setCityValue] = useState(deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.city ? deliveryOrder.receiver.delivery_receiver.city : '');
    const [department, setDepartment] = useState(deliveryOrder.receiver && deliveryOrder.receiver.address ? deliveryOrder.receiver.address : '');
    const [weight, setWeight] = useState(null);
    const [isIPay, setIsIPay] = useState(false);
    const [saveData, setSaveData] = useState('');

    const auditDates = (value, fieldName) => {
        if (saveData !== value) {
            getAccessToken().then((token) => {
                setDeliverNP(token, lastOrderId, {[fieldName]: value}).then((res) => {
                    console.log(res);
                })
            });
        }
    }

    useEffect(() => {
        if (deliveryOrder && deliveryOrder.delivery) {
            deliveryOrder.delivery.payer === 'Sender' ? setIsIPay(true) : setIsIPay(false);
            console.log(deliveryOrder)
        }
        if (deliveryOrder && deliveryOrder.specified_weight) {
            setWeight(deliveryOrder.specified_weight)
        }
    }, [deliveryOrder])

    const formValues = {
        first_name: deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.first_name ? deliveryOrder.receiver.delivery_receiver.first_name : '',
        last_name: deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.last_name ? deliveryOrder.receiver.delivery_receiver.last_name : '',
        phone: deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.phone ? deliveryOrder.receiver.delivery_receiver.phone : '',
        declared_order_price: deliveryOrder.delivery && deliveryOrder.delivery.estimate_order_price ? deliveryOrder.delivery.estimate_order_price : '',
        declared_delivery_price: deliveryOrder.delivery && deliveryOrder.delivery.estimate_delivery_price ? deliveryOrder.delivery.estimate_delivery_price : '',
        ttn: deliveryOrder.delivery && deliveryOrder.delivery.ttn ? deliveryOrder.delivery.ttn : '',
        comment: deliveryOrder.delivery && deliveryOrder.delivery.comment ? deliveryOrder.delivery.comment : '',
    }
    const {handleSubmit, handleChange, values, setValues} = useFormik({
        initialValues: formValues,

        onSubmit: (values) => {
            console.log(values)
        },
    });

    const handleSetValues = (newValues) => {
        setValues(prevValues => ({
            ...prevValues,
            first_name: newValues.first_name,
            last_name: newValues.last_name,
            phone: newValues.phone,
        }));
        setCityValue(newValues.city);
    }

    const updateData = (value) => {
        getAccessToken().then((token) => {
            setDeliverNP(token, lastOrderId, value).then((res) => console.log(res))
        });
    }

    return (
        <div className={`${styles.deliveryNP} deliveryNP`}>
            <form onSubmit={handleSubmit}>
                <div className={styles.deliveryNP__content}>
                    <div className={styles.deliveryNP__row}>
                        <input
                            placeholder={'І`мя Отримувача'}
                            className={'baseInput'}
                            id="first_name"
                            name="first_name"
                            type="text"
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            value={values.first_name}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'first_name')}
                        />
                        <input
                            placeholder={'Прізвище Отримувача'}
                            className={'baseInput'}
                            id="last_name"
                            name="last_name"
                            type="text"
                            onChange={handleChange}
                            value={values.last_name}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'last_name')}
                        />
                    </div>
                    <div>
                        <input
                            placeholder={'Телефон Отримувача'}
                            className={'baseInput'}
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={handleChange}
                            value={values.phone}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'phone')}
                        />
                    </div>
                    <div className={styles.deliveryNP__row}>
                        <NovaPoshtaCities
                            city={city}
                            setCity={setCity}
                            cityValue={cityValue}
                            setCityValue={setCityValue}
                            setDepartment={setDepartment}
                            updateData={(values) => updateData(values)}
                        />
                        <NovaPoshtaDepartments
                            city={city}
                            setDepartment={setDepartment}
                            department={department}
                            updateData={(values) => updateData(values)}
                        />
                    </div>
                    <NovaPoshtaWeight weight={weight} setWeight={setWeight} auditDates={auditDates}/>
                    <DeliveryIPay isIPay={isIPay} setIsIPay={setIsIPay} auditDates={auditDates}/>
                    <div>
                        <input
                            placeholder={'Оголошена вартість'}
                            className={'baseInput'}
                            id="declared_order_price"
                            name="declared_order_price"
                            type="number"
                            onChange={handleChange}
                            value={values.declared_order_price}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'estimate_order_price')}
                        />
                    </div>
                    <div className={styles.closedInput}>
                        <input
                            placeholder={'Орієнтовна Вартість Доставки'}
                            className={'baseInput'}
                            id="declared_delivery_price"
                            name="declared_delivery_price"
                            type="number"
                            value={values.declared_delivery_price}
                            readOnly
                        />
                    </div>
                    <div className={styles.transportNumber}>
                        <input
                            placeholder={'ТТН'}
                            className={'baseInput'}
                            id="ttn"
                            name="ttn"
                            type="number"
                            value={values.ttn}
                            readOnly
                        />
                        <CopyToClipboard text={values.ttn} className={styles.copyButton}>
                            <button type='button'>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div>
                        <textarea
                            cols="30"
                            rows="10"
                            placeholder={'Коментар'}
                            id="comment"
                            name="comment"
                            onChange={handleChange}
                            value={values.comment}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'comment')}
                        />
                    </div>
                    <div className={styles.action}>
                        <button className={'globalBtn'} type='submit'>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </form>
            <Parser
                values={{
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone: values.phone,
                    city: cityValue
                }}
                setValues={handleSetValues}
                updateData={updateData}
                parserStyles={{backgroundColor: '#F0B5B7'}}
            />
        </div>
    )
}

export default DeliveryNP;