import React from "react";
import styles from '../Payment.module.scss';
import PaymentGroup from '../../../../../assets/img/paymentBtnGroup.png';


const Button = ({OnPay}) => {

    return (
       <button className={styles.paymentBtn} onClick={() => OnPay()}>
           <img src={PaymentGroup} alt=""/>
       </button>
    )
}

export default Button;