import React, {useState} from "react";
import styles from './DetailsInput.module.scss';
import {useAppDispatch} from "../../../../../hooks/redux";
import {setIsActivePopup} from "../../../../../store/main-slice";
import GlobalPopup from "../../../../Common/GlobalPopup/GlobalPopup";
import ClientInfo from "../../ClientInfo/ClientInfo";


const DetailsInput = () => {
    const dispatch = useAppDispatch();
    const [phoneDigits, setPhoneDigits] = useState('');
    const [showInfo, setShowInfo] = useState(false);

    let invoice = 'ТТН 89343473647382487';
    let link = '#';
    let post = 'Нова пошта 25';

    const handleChange = (e) => {
        const value = e.target.value;
        const valid = /^[0-9]*$/;
        if ((value === '' || valid.test(value))) {
            setPhoneDigits(value);
        }
    }

    const sendNumber = (number) => {
        setShowInfo(true);
        dispatch(setIsActivePopup(true));
    }

    const onClose = () => {
        setShowInfo(false)
    }

    return (
        <div className={styles.inputBlock}>
            <input type="text" name="order_digits" value={phoneDigits}
                   placeholder="Введіть 4 останніх цифри телефону отримувача"
                   onChange={(e) => handleChange(e)} maxLength="4"/>
            <button className={styles.inputBlock__button} disabled={phoneDigits.length !== 4} onClick={() => sendNumber(phoneDigits)}>
                <span>OK</span>
            </button>
            {showInfo && (
                <GlobalPopup title={'Інформація клієнта'} topPopup onClose={() => onClose()}>
                    <ClientInfo invoice={invoice} link={link} post={post}/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default DetailsInput;