import React from "react";
import styles from './Requisities.module.scss';
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../assets/icons/copy.svg";

const Requisites = () => {
    return (
        <div className={styles.requisites}>
            <h2>Реквізити для оплати</h2>
            <div className={styles.content}>
                <h3>Банк отримувача:</h3>
                <div className={styles.content__box}>
                    <span className={styles.content__title}>Призначення платежу:</span>
                    <p className={styles.warning}>Обов'язково скопіюйте призначення платежу</p>
                    <div className={styles.content__field}>
                        <div className={styles.content__leftField}>
                            <span>Оплата за товар</span>
                        </div>
                        <CopyToClipboard text={'Оплата за товар'}>
                            <button>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className={styles.content__box}>
                    <span className={styles.content__title}>Назва отримувача:</span>
                    <div className={styles.content__field}>
                        <div className={styles.content__leftField}>
                            <span>Адаменко Анжеліка Миколаївна</span>
                        </div>
                        <CopyToClipboard text={'Адаменко Анжеліка Миколаївна'}>
                            <button>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className={styles.content__box}>
                    <span className={styles.content__title}>Код ЄДРПОУ:</span>
                    <div className={styles.content__field}>
                        <div className={styles.content__leftField}>
                            <span>3653509001</span>
                        </div>
                        <CopyToClipboard text={'3653509001'}>
                            <button>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className={styles.content__box}>
                    <span className={styles.content__title}>Рахунок отримувача:</span>
                    <div className={styles.content__field}>
                        <div className={styles.content__leftField}>
                            <span>UA323052990000026007026000240</span>
                        </div>
                        <CopyToClipboard text={'UA323052990000026007026000240'}>
                            <button>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className={styles.content__box}>
                    <span className={styles.content__title}>або карта ФОП</span>
                    <div className={styles.content__field}>
                        <div className={styles.content__leftField}>
                            <span>05299000002600702600</span>
                        </div>
                        <CopyToClipboard text={'05299000002600702600'}>
                            <button>
                                <img src={copyIcon} alt=""/>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Requisites