import React from "react";
import styles from './FabricPrice.module.scss';

const FabricPrice = () => {

    return (
        <div className={styles.price}>
            <p>Ціна комплекту:</p>
            <span className={styles.price}>8200 грн</span>
        </div>
    )
}

export default FabricPrice