import React from "react";
import styles from './FabricTypeTabs.module.scss';
import FabricTypeFabrics from "./FabricTypeFabrics/FabricTypeFabrics";

const FabricTypeTabs = ({type, setType, fabricTypes}) => {

    const onSubmit = (value) => {
        if (type !== value) {
            setType(value)
        }
    }

    return (
        <div className={styles.type}>
            <h3>Пара тканин</h3>
            <div className={styles.mainTabs}>
                <div className={styles.tabs}>
                    {fabricTypes.map((fabricType) => (
                        <button
                            key={fabricType.code}
                            className={type === fabricType.code ? styles.tabActive : styles.tab}
                            onClick={() => onSubmit(fabricType.code)}
                        >
                            <span>{fabricType.code}</span>
                        </button>
                    ))}
                </div>
                <p className={styles.line}></p>
            </div>
            <FabricTypeFabrics fabricTypes={fabricTypes} selectedType={type}/>
        </div>
    )
}

export default FabricTypeTabs