import React, {useState} from "react";
import BtnGroup from "./Button1/Button";
import Btn from "./Button2/Button";
import IbanBtn from "./Button3/Button";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import {useAppDispatch} from "../../../../hooks/redux";
import {setIsActivePopup} from "../../../../store/main-slice";
import PaymentDone from "../PaymentDone/PaymentDone";

const Payment = () => {
    const dispatch = useAppDispatch();
    const [showPayInfo, setShowPayInfo] = useState(false);

    const OnPay = () => {
        setShowPayInfo(true);
        dispatch(setIsActivePopup(true));
    }

    const onClose = () => {
        setShowPayInfo(false)
    }

    return (
        <div>
            <h3>Оплатити онлайн</h3>
            <BtnGroup OnPay={OnPay}/>
            <IbanBtn/>
            <Btn/>
            {showPayInfo && (
                <GlobalPopup title={'Оплата'} onClose={onClose} topPopup>
                    <PaymentDone />
                </GlobalPopup>
            )}
        </div>
    )
}

export default Payment;