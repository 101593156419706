import React from "react";
import styles from './PaymentDone.module.scss'
import doneIcon from "../../../../assets/icons/done.svg";
import Link from "../Link/Link";

const PaymentDone = () => {

    return (
        <div className={styles.done}>
            <img src={doneIcon} className={styles.done__check} alt=""/>
            <h2>Оплата успішно проведена!<br/>Дякуємо за замовлення!</h2>
            <a href="#" className={styles.done__back}>Повернутись до замовлення</a>
            <Link/>
        </div>
    )
}

export default PaymentDone;