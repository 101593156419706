import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {getAccessToken, getDepartment} from "../../../../api/api";


const NovaPoshtaDepartments = ({city, setDepartment, department, updateData}) => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        if (city && city.id) {
            getAccessToken().then((token) => {
                getDepartment(token, city.id).then((response) => {
                    if (response && response.warehouses) {
                        const { warehouses } = response;
                        if (Array.isArray(warehouses)) {
                            const changerArray = warehouses.map((item) => {
                                return (
                                    {
                                        label: item.address,
                                        address_ref: item.address_ref,
                                        city: item.city,
                                        city_ref: item.city_ref,
                                        index: item.index,
                                        max_weight_allowed: item.max_weight_allowed,
                                        max_weight_allowed_place: item.max_weight_allowed_place,
                                        max_weight_allowed_total: item.max_weight_allowed_total,
                                        number: item.number
                                    }
                                )
                            })
                            setDepartments(changerArray)
                        } else {
                            const changerObject = {
                                label: warehouses.address,
                                address_ref: warehouses.address_ref,
                                city: warehouses.city,
                                city_ref: warehouses.city_ref,
                                index: warehouses.index,
                                max_weight_allowed: warehouses.max_weight_allowed,
                                max_weight_allowed_place: warehouses.max_weight_allowed_place,
                                max_weight_allowed_total: warehouses.max_weight_allowed_total,
                                number: warehouses.number
                            }
                            setDepartments([changerObject])
                        }
                    }
                })
            })
        }
    }, [city])

    return (
        <>
            <Autocomplete
                disablePortal
                options={departments}
                value={department}
                noOptionsText="No cities"
                onChange={(event, newValue) => {
                    setDepartment(newValue);
                    if (newValue && newValue.label) {
                        updateData({
                            'address': newValue.label,
                            'address_ref': newValue.address_ref,
                            'max_weight_allowed': newValue.max_weight_allowed,
                            'warehouse_number': newValue.number,
                        });
                    }
                }}
                renderInput={(params) => <TextField {...params} label="Номер відділення" />}
            />
        </>
    )
}

export default NovaPoshtaDepartments;