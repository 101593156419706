import React, {useEffect, useState} from "react";
import styles from './StoragePopup.module.scss';
import searchIcon from "../../../../assets/icons/search.svg";
import StorageItem from "../../../Common/StorageItem/StorageItem";
import {getAccessToken, getStorageProducts} from "../../../../api/api";

const StoragePopup = () => {

    const [storageList, setStorageList] = useState([]);

    useEffect(() => {
        if (storageList && storageList.length === 0) {
            getAccessToken().then((token) => {
                getStorageProducts(token).then((res) => {
                    if (res && res.length > 0) {
                        setStorageList(res)
                    }
                })
            })
        }
    }, [])

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <input type="search" placeholder={'Пошук'} className={'baseInput'}/>
                <img src={searchIcon} alt=""/>
            </div>
            {
                storageList.map(({name, quantity, image, size, color}, index) => <StorageItem key={index} name={name} quantity={quantity} image={image} size={size} color={color}/>)
            }
        </div>
    )
}

export default StoragePopup;