import React from "react";
import styles from './FabricTypeFabrics.module.scss';
import FabricTypeItem from "./FabricTypeItem";

const FabricTypeFabrics = ({fabricTypes, selectedType}) => {

    const selectedFabricType = fabricTypes.find(fabricType => fabricType.code === selectedType);

    return (
        <div className={styles.fabric}>
            <div className={styles.fabric__row}>
                {selectedFabricType.fabrics.map((item) => <FabricTypeItem key={item.name} item={item}/>)}
            </div>
        </div>
    )
}

export default FabricTypeFabrics