const baseUrl = 'https://panel.egodevelopment.pp.ua/api/v1/';

export const setTokens = (access, refresh) => {
    try {
        localStorage.setItem('accessEgoToken', access);
        localStorage.setItem('refreshEgoToken', refresh);
    } catch (e) {
        console.log(e);
    }
}

export const logout = async () => {
    try {
        localStorage.removeItem('accessEgoToken');
        localStorage.removeItem('refreshEgoToken');
        return 'ok';
    } catch (e) {
        console.log(e);
    }
}

export const setNewAccessToken = async (token) => {
    try {
        localStorage.setItem('accessEgoToken', token)
        return 'ok';
    } catch (e) {
        console.log(e);
    }
}

export const getAccessToken = async () => {
    try {
        return await localStorage.getItem('accessEgoToken');
    } catch (e) {
        console.log(e);
    }
};

export const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshEgoToken');
        const response = await fetch(`${baseUrl}users/token/refresh/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh: refreshToken,
            })
        });
        const newAccessToken = response.json();
        newAccessToken.then((res) => {
            setNewAccessToken(res.access).then((res) => {
                if (res) {
                    return newAccessToken;
                }
            })
        })
        return newAccessToken;
    } catch (error) {
        console.log(error);
    }
}

export const login = async (name, password) => {
    try {
        const response = await fetch(`${baseUrl}users/token/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: name,
                password: password,
            })
        });
        const result = response.json();
        result.then((res) => {
            if (res && res.access && res.refresh) {
                setTokens(res.access, res.refresh);
            } else {
                return result;
            }
        })
        return result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserInfo = async (token) => {
    try {
        const response = await fetch(`${baseUrl}users/`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getUserInfo(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getStorageProducts = async (token) => {
    try {
        const response = await fetch( `${baseUrl}products/warehouse-items` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getStorageProducts(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getFabrics = async (token) => {
    try {
        const response = await fetch( `${baseUrl}products/fabric` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getFabrics(res.access).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getOrders = async (token, id = null, isFavourite = null) => {
    try {
        const response = await fetch( id && isFavourite ? `${baseUrl}orders/?manager=${id}&is_favourite=${isFavourite}`
            : id && !isFavourite ? `${baseUrl}orders/?manager=${id}`
                : `${baseUrl}orders/` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrders(res.access, id, isFavourite).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setOrderDuplicate = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}orders/duplicate/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: id,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setOrderDuplicate(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getOrdersRecentViews = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}orders/recent-views/?manager=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrdersRecentViews(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}


export const getDeliverNP = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/?order_id=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getDeliverNP(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setDeliverNP = async (token, id, value = null) => {
    console.log(value)
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: id,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setDeliverNP(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}


export const getCities = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/location/city/?city=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getCities(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getDepartment = async (token, value) => {
    try {
        const response = await fetch( `${baseUrl}delivery/nova_poshta/location/warehouse/?city_ref=${value}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getDepartment(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const setNewOrder = async (token, managerId) => {
    try {
        const response = await fetch( `${baseUrl}orders/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                manager: managerId,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return setNewOrder(res.access, managerId).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const getOrderInfo = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}orders/${id}/` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getOrderInfo(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const updateCustomer = async (token, orderId, value) => {
    try {
        const response = await fetch( `${baseUrl}customers/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updateCustomer(res.access, orderId, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const updatePayment = async (token, orderId, value) => {
    try {
        const response = await fetch( `${baseUrl}payments/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updatePayment(res.access, orderId, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const updateOrder = async (token, orderId, managerId, value) => {
    try {
        const response = await fetch( `${baseUrl}orders/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: orderId,
                manager: managerId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updateOrder(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const removeOrder = async (token, id) => {
    try {
        const response = await fetch( `${baseUrl}orders/${id}/` , {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return removeOrder(res.access, id).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.status;
        }

    } catch (error) {
        console.log(error);
    }
}

export const getSearchResult = async (token, value, is_order= true, is_fabric = true, is_warehouse_item = true) => {
    try {
        const response = await fetch( `${baseUrl}search/?query=${value}&is_order=${is_order}&is_fabric=${is_fabric}&is_warehouse_item=${is_warehouse_item}` , {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return getSearchResult(res.access, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}