import React from "react";
import styles from './ClientInfo.module.scss'
import OrderInfoBlock from "../../../Common/OrderInfoBlock/OrderInfoBlock";

const ClientInfo = ({invoice, link, post}) => {
    /*const invoice = 'ТТН 89343473647382487';*/

    return (
        <div className={styles.info}>
           <OrderInfoBlock invoice={invoice} link={link} post={post}/>
            <div className={styles.info__customer}>
                <p><span>Ім'я: </span>Дмитро</p>
                <p><span>Прізвище: </span>Мазур</p>
                <p><span>По батькові: </span>Володимирович</p>
                <p><span>Телефон замовника: </span>093 422 87 90</p>
                <p><span>Місто: </span>Київ</p>
            </div>
        </div>
    )
}

export default ClientInfo;