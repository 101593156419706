import React, {useState} from "react";
import styles from './FabricChooserList.module.scss';
import FabricChooserSmall from "./FabricChoose";
import arrow from '../../../../assets/icons/arrowDown.svg';
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import {useAppDispatch} from "../../../../hooks/redux";
import {setIsActivePopup} from "../../../../store/main-slice";
import ChoosePopup from "./ChoosePopup/ChoosePopup";

const FabricChooserList = ({fabricsList, handleChange, selectedFabric}) => {
    const dispatch = useAppDispatch();

    const smallList = fabricsList && fabricsList.length > 4
        ? [fabricsList[0], fabricsList[1], fabricsList[2], fabricsList[3]]
        : null

    return (
        <>
            {smallList ? (
                    <ul className={styles.fabricChooser}>
                        {
                            smallList.map((item, index) => (
                                <FabricChooserSmall selectedFabric={selectedFabric} handleChange={handleChange} key={index}
                                                    item={item}/>
                            ))
                        }
                        <button className={styles.fabricChooser__else}
                                onClick={() => dispatch(setIsActivePopup(true))}>
                            <img src={arrow} alt=""/>
                        </button>
                    </ul>

                )
                : (
                    <ul className={styles.fabricChooser}>
                        {fabricsList.map((item, index) => (
                            <FabricChooserSmall selectedFabric={selectedFabric} handleChange={handleChange} key={index}
                                                item={item}/>
                        ))}
                    </ul>
                )
            }
            <GlobalPopup title={'Типи тканин:'}>
                <ChoosePopup selectedFabric={selectedFabric} fabricsList={fabricsList} handleChange={handleChange}/>
            </GlobalPopup>
        </>
    )
}

export default FabricChooserList;