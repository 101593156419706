import React, {useEffect, useRef, useState} from "react";
import styles from './FabricSet.module.scss';
import OptionButton from "./OptionButton/OptionButton";
import CommentIcon from "../../../../assets/icons/comment.svg";
import OptionIcon from "../../../../assets/icons/options.svg";
import ReturnIcon from "../../../../assets/icons/return.svg";
import {setIsActivePopup} from "../../../../store/main-slice";
import {useAppDispatch} from "../../../../hooks/redux";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import PopupCheckboxes from "../../../Common/PopupCheckboxes/PopupCheckboxes";
import EditSet from "../../../../assets/icons/editSet.svg";
import DeleteSet from "../../../../assets/icons/delete.svg";

const FabricSet = ({openCommentPopup, openOptionPopup, inPopup}) => {

    const [activeSize, setActiveSize] = useState(0);
    const [activeOption, setActiveOption] = useState(0);
    const [activeCircle, setActiveCircle] = useState(0);

    let sizes = [
        {"title": "70х70", "value": "70х70"},
        {"title": "70х50", "value": "70х50"},
        {"title": "60х40", "value": "60х40"},
        {"title": "50х50", "value": "50х50"},
        {"title": "60x60", "value": "60x60"}
    ]


    let options = ['Стандарт', 'Вушка', 'Замочок'];

    let circles = ['A', 'B'];

    const [showSizePopup, setShowSizePopup] = useState(false);
    const [additionalSize, setAdditionalSize] = useState('');
    const [containerPosition, setContainerPosition] = useState(2);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const [sortedSizes, setSortedSizes] = useState(sizes);
    const containerRef = useRef(null);
    const leftHiddenLinkRef = useRef(null);
    const rightHiddenLinkRef = useRef(null);
    const dispatch = useAppDispatch();

    const handleSizeClick = (sizeIndex) => {
        setContainerPosition(2);
        if (sizeIndex !== activeSize) {
            setActiveSize(sizeIndex);
        }
    };

    const handleOptionClick = (optionIndex) => {
        setContainerPosition(2);
        if (optionIndex !== activeOption) {
            setActiveOption(optionIndex);
        }
    };

    const handleCircleClick = (circleIndex) => {
        setContainerPosition(2);
        setActiveCircle(circleIndex);
    };

    const handleReturn = () => {
        setContainerPosition(2);
        console.log('return')
    };

    const openSizePopup = () => {
        setContainerPosition(2);
        setShowSizePopup(true);
        dispatch(setIsActivePopup(true));
    }

    const handleCommentPopup = () => {
        setContainerPosition(2);
        openCommentPopup();
    }

    const handleOptionPopup = () => {
        setContainerPosition(2);
        openOptionPopup();
    }

    const handleTouchStart = (e) => {
        setTouchStartX(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStartX - touchEndX < -50 && containerPosition > 1) {
            setContainerPosition(containerPosition - 1);
        } else if (touchStartX - touchEndX > 50 && containerPosition < 3) {
            setContainerPosition(containerPosition + 1);
        }
    };


    const handleAdditionalSize = (value) => {
        let newSize = value.value;
        setAdditionalSize(newSize);
        const index = sizes.findIndex(size => size.value === newSize);
        if (index !== -1) {
            if (!sizes.slice(0, 3).some(size => size.value === newSize)) {
                let changedSizes = [...sizes];
                changedSizes.splice(index,1);
                changedSizes.unshift(value);
                setSortedSizes(changedSizes);
                setActiveSize(0);
            } else {
                setSortedSizes(sizes);
                setActiveSize(index);
            }
        }
    }

    //outside click detection for container swipe
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current &&
                !containerRef.current.contains(event.target) &&
                !leftHiddenLinkRef.current.contains(event.target) &&
                !rightHiddenLinkRef.current.contains(event.target)) {
                setContainerPosition(2);
            }
        };

        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [containerRef]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content} style={{ left: containerPosition === 1 ? '0' : containerPosition === 3 ? '-92px' : '-46px' }}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd} ref={containerRef}>
                <button onClick={null} className={styles.hiddenLeftBtn} ref={leftHiddenLinkRef}>
                    <img src={EditSet} alt=""/>
                </button>
                <div className={`${styles.setWrap} ${inPopup ? styles.inPopup : ""}`}>
                    <div className={styles.set}>
                        <div className={styles.mainRow}>
                            <div className={styles.set__color} onClick={() => setContainerPosition(2)}></div>
                            <div className={styles.set__panel}>
                                <div className={`${styles.row} ${styles.titleRow}`}>
                                    <p>Наволочка: <span className={styles.price}>3500 грн</span></p>
                                    <OptionButton img={CommentIcon} action={!inPopup ? handleCommentPopup : null}/>
                                </div>
                                <div className={`${styles.row} ${styles.featureRow}`}>
                                    <div className={styles.size}>
                                        {sizes.length <= 4 && sortedSizes.slice(0, sizes.length).map((el, index) => (
                                            <div
                                                key={index}
                                                className={`${styles.size__item} ${activeSize === index ? styles.active : ""}`}
                                                onClick={() => handleSizeClick(index)}
                                            >
                                                <p>{el.value}</p>
                                            </div>
                                        ))}
                                        {sizes.length > 4 && sortedSizes.slice(0, 3).map((el, index) => (
                                            <div
                                                key={index}
                                                className={`${styles.size__item} ${activeSize === index ? styles.active : ""}`}
                                                onClick={() => handleSizeClick(index)}
                                            >
                                                <p>{el.value}</p>
                                            </div>
                                        ))}
                                        {sizes.length > 4 && (
                                            <div className={`${styles.size__circle}`} onClick={() => openSizePopup()}>
                                                <p>+{sizes.length - 3}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.circleContainer}>
                                        {circles.map((el, index) => (
                                            <div
                                                key={index}
                                                className={`${styles.circle} ${activeCircle === index ? styles.active : ""}`}
                                                onClick={() => handleCircleClick(index)}
                                            >
                                                <span>{el}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.optionRow}>
                            <div>
                                <span>Опції:</span>
                                <div className={styles.optionGroup}>
                                    {options.map((el, index) => (
                                        <button
                                            key={index}
                                            className={`${styles.option} ${activeOption === index ? styles.active : ""}`}
                                            onClick={() => handleOptionClick(index)}
                                        >
                                            <span>{el}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <OptionButton img={OptionIcon} action={!inPopup ? handleOptionPopup : null}/>
                        </div>
                    </div>
                    {!inPopup && <button className={styles.returnButton} onClick={() => handleReturn()}>
                        <img src={ReturnIcon} alt=""/>
                    </button>}
                </div>
                <button onClick={null} className={styles.hiddenRightBtn} ref={rightHiddenLinkRef}>
                    <img src={DeleteSet} alt=""/>
                </button>
            </div>

            {showSizePopup && (
                <GlobalPopup title={'Вказати розмір:'} onClose={() => setShowSizePopup(false)}>
                    <PopupCheckboxes values={additionalSize} setValues={handleAdditionalSize} chooseList={sizes} isRadio/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default FabricSet