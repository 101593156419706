import React, {useState} from "react";
import styles from './FabricSize.module.scss';

const FabricSize = () => {

    let data = [{abbr: '1,5', name: 'Полуторний'}, {abbr: '2,0', name: 'Двоспальний'},
        {abbr: 'Є', name: 'Євро'}, {abbr: 'Є max', name: 'Євро Максі'}, {abbr: '7,Я', name: 'Сімейний'}];

    const [activeSize, setActiveSize] = useState({index: null, standard: 0});

    const handleSizeClick = (sizeIndex) => {
        if (activeSize.index !== sizeIndex) {
            setActiveSize({standard: 1, index: sizeIndex});
        } else if (activeSize.index === sizeIndex && activeSize.standard === 1) {
            setActiveSize({standard: 0, index: sizeIndex})
        } else {
            setActiveSize({standard: 0, index: null})
        }
    };

    return (
        <div className={styles.size}>
            <div className={styles.size__row}>
                {data.map((el, index) => (
                    <div key={index}
                         className={`${styles.item} ${activeSize.index === index && activeSize.standard ? styles.activeStandard : ""} ${activeSize.index === index && !activeSize.standard ? styles.activeNotStandard : ""}`}
                         onClick={() => handleSizeClick(index)}>
                        <div className={styles.item__panel}>
                            <span>{el.abbr}</span>
                        </div>
                        <p>{el.name}</p>
                    </div>
                ))}
            </div>
            <div className={styles.size__descWrap}>
                <div className={styles.desc}>
                    <span className={styles.desc__color}></span>
                    <p>Стандарт</p>
                </div>
                <div className={styles.desc}>
                    <span className={`${styles.desc__color} ${styles.desc__notStandard}`}></span>
                    <p>Не стандарт</p>
                </div>
            </div>
        </div>
    )
}

export default FabricSize