import React, {useEffect, useState} from "react";
import styles from './StorageItem.module.scss';
import IconPlaceholder from "../../../assets/img/storage_item_icon_placeholder.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../../assets/icons/copy.svg";
import plus from "../../../assets/icons/plus.svg";
import closeIcon from "../../../assets/icons/closeCross.svg";
import PopupCheckboxes from "../PopupCheckboxes/PopupCheckboxes";

const StorageItem = ({name, quantity, image, size, color, copy}) => {

    let basicCount = 4;
    const [activeSizeIndex, setActiveSizeIndex] = useState(0);
    const [activeColorIndex, setActiveColorIndex] = useState(0);
    const [additionalSize, setAdditionalSize] = useState('');
    const [additionalColor, setAdditionalColor] = useState('');
    const [isShowSizePopup, setIsShowSizePopup] = useState(false);
    const [isShowColorPopup, setIsShowColorPopup] = useState(false);
    const [sortedSizes, setSortedSizes] = useState([]);
    const [initialSizes, setInitialSizes] = useState([]);
    const [initialColors, setInitialColors] = useState([]);
    const [sortedColors, setSortedColors] = useState([]);

    useEffect(() => {
        let formattedColors = [];
        if(Array.isArray(color)) {
            formattedColors = color.map((el) => {
                return {title: el.name, value: el.name}
            });

        } else if (color && color.name)  {
            formattedColors = [];
            formattedColors.push ({title: color.name, value: color.name})
        }
        setInitialColors(formattedColors);
        setSortedColors(formattedColors);
        setAdditionalColor(formattedColors[0].value)
    }, [color]);

    useEffect(() => {
        let formattedSizes = [];
        if(Array.isArray(size)) {
            formattedSizes = size.map((el) => {
                return {title: `${el.width}x${el.length}`, value: `${el.width}x${el.length}`}
            });

        } else if (size && size.length)  {
            formattedSizes = [];
            formattedSizes.push ({title: `${size.width}x${size.length}`, value: `${size.width}x${size.length}`})
        }
        setInitialSizes(formattedSizes);
        setSortedSizes(formattedSizes);
        setAdditionalSize(`${formattedSizes[0].width}x${formattedSizes[0].length}`)
    }, [size]);

    const handleSizeClick = (sizeIndex, value) => {
        if (sizeIndex !== activeSizeIndex) {
            setActiveSizeIndex(sizeIndex);
            setAdditionalSize(value);
        }
    };

    const handleAdditionalSize = (value) => {
        let newSize = value.value;
        setAdditionalSize(newSize);
        const index = initialSizes.findIndex(size => size.value === newSize);
        if (index !== -1) {
            if (!initialSizes.slice(0, basicCount).some(size => size.value === newSize)) {
                let changedSizes = [...initialSizes];
                changedSizes.splice(index,1);
                changedSizes.unshift(value);
                setSortedSizes(changedSizes);
                setActiveSizeIndex(0);
            } else {
                setSortedSizes(initialSizes);
                setActiveSizeIndex(index);
            }
        }
    }

    const handleColorClick = (colorIndex, value) => {
        if (colorIndex !== activeColorIndex) {
            setActiveColorIndex(colorIndex);
            setAdditionalColor(value);
        }
    };

    const handleAdditionalColor = (value) => {
        let newColor = value.value;
        setAdditionalColor(newColor);
        const index = initialColors.findIndex(color => color.value === newColor);
        if (index !== -1) {
            if (!initialColors.slice(0, basicCount).some(color => color.value === newColor)) {
                let changedColors = [...initialColors];
                changedColors.splice(index,1);
                changedColors.unshift(value);
                setSortedColors(changedColors);
                setActiveColorIndex(0);
            } else {
                setSortedColors(initialColors);
                setActiveColorIndex(index);
            }
        }
    }

    const openSizePopup = () => {
        setIsShowSizePopup(true);
    }

    const closeSizePopup = () => {
        setIsShowSizePopup(false);
    }

    const openColorPopup = () => {
        setIsShowColorPopup(true);
    }

    const closeColorPopup = () => {
        setIsShowColorPopup(false);
    }

    return (
        <div className={styles.storageItem}>
            <div className={styles.infoWrap}>
                <div className={styles.titleWrap}>
                    {image ? <img src={image} className={styles.itemImg} alt=""/> :
                        <span className={styles.iconWrap}><img src={IconPlaceholder} alt=""/></span>}
                    <div>
                        <h3>{name}</h3>
                        <p className={parseInt(quantity) < 10 ? styles.balanceWarning : ''}>Залишок {quantity}</p>
                    </div>
                </div>
                {copy? <CopyToClipboard text={name}>
                    <button>
                        <img src={copyIcon} alt=""/>
                    </button>
                </CopyToClipboard> :
                <div className={styles.addWrap}>
                    <button>
                        <img src={plus} alt=""/>
                    </button>
                    <span>Додати</span>
                </div>}
            </div>
            <div className={styles.optionsWrap}>
                <div>
                    {sortedSizes.slice(0, basicCount).map((el, index) => (
                        <span
                            key={index}
                            className={`${activeSizeIndex === index ? styles.active : ""}`}
                            onClick={() => handleSizeClick(index, el.value)}
                        >
                            <p>{el.value}</p>
                        </span>
                    ))}
                    {initialSizes.length > 4 && (
                        <span className={styles.additional} onClick={() => openSizePopup()}>
                            <p>+{initialSizes.length - basicCount}</p>
                        </span>
                    )}
                </div>
                <div className={styles.colorsRow}>
                    {sortedColors.slice(0, basicCount).map((el, index) => (
                        <span
                            key={index}
                            className={`${activeColorIndex === index ? styles.active : ""}`}
                            onClick={() => handleColorClick(index, el.value)}
                            style={{
                                backgroundColor: el.value,
                                borderColor: activeColorIndex === index ? '' : el.value
                            }}
                        >
                        </span>
                    ))}
                    {initialColors.length > 4 && (
                        <span className={styles.additional} onClick={() => openColorPopup()}>
                            <p>+{initialColors.length - basicCount}</p>
                        </span>
                    )}
                </div>
            </div>
            {(isShowSizePopup || isShowColorPopup) && (
                <span className={styles.shadowActive}/>
            )}
            {isShowSizePopup && (
                <div className={isShowSizePopup ? styles.globalPopupActive : styles.globalPopup}>
                    <div className={styles.header}>
                        <p className={styles.header__title}>Вказати розмір:</p>
                        <div className={styles.header__close} onClick={() => closeSizePopup()}>
                            <span>Закрити</span>
                            <button>
                                <img src={closeIcon} alt=""/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.globalPopup__content}>
                        <PopupCheckboxes values={additionalSize} setValues={handleAdditionalSize} chooseList={initialSizes} isRadio/>
                    </div>
                </div>
            )}
            {isShowColorPopup && (
                <div className={isShowColorPopup ? styles.globalPopupActive : styles.globalPopup}>
                    <div className={styles.header}>
                        <p className={styles.header__title}>Вказати колір:</p>
                        <div className={styles.header__close} onClick={() => closeColorPopup()}>
                            <span>Закрити</span>
                            <button>
                                <img src={closeIcon} alt=""/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.globalPopup__content}>
                        <PopupCheckboxes values={additionalColor} setValues={handleAdditionalColor} chooseList={initialColors} isRadio/>
                    </div>
                </div>
            )}

        </div>
    )
}

export default StorageItem;