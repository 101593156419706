import React from "react";
import Img from "../../../../assets/img/slide.png";
import ProductItem from "../../../Common/ProductItem/ProductItem";


const List = () => {
    let productInfo = [
        {
            images: [Img, Img],
            code: 'А: N015',
            stock: 1,
            type: 'Двоспальний',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка'],
            prices: ['1800 грн', '2200 грн', '1850 грн']
        },
        {
            images: [],
            code: 'А: N015',
            stock: 1,
            type: 'Сімейний',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка'],
            prices: ['1800 грн', '2200 грн', '1850 грн']
        }, {
            name: 'Електропростинь',
            images: [],
            code: 'А: N015',
            stock: 1,
            type: '',
            content: ['Наволочка (з Вушками)', 'Підковдра (Стандарт)', 'Простирадло (на резинці)'],
            additionalOptions: ['Подарункова упаковка'],
            prices: ['1800 грн', '2200 грн', '1850 грн']
        }]

    return (
        <div>
            <h3>Товари в замовленні</h3>
            <div>
                {productInfo.map((el, index) => <ProductItem item={el} key={index}/>)}
            </div>
        </div>
    )
}

export default List;